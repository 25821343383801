import { AsyncPipe } from '@angular/common';
import { Component, ErrorHandler, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenIdConfiguration } from 'angular-auth-oidc-client';
import { AuthResult } from 'angular-auth-oidc-client/lib/flows/callback-context';
import { lastValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';

import { ToastService } from '@site-mate/global-web-ui';
import {
  CreateConnectionMetadata,
  IConnection,
  IConnectionType,
  IntegrationType,
  IntegrationUtil,
  IOAuthCredential,
  Maybe,
} from '@site-mate/sitemate-flowsite-shared';

import { ConnectionService } from 'app/core/services/connection.service';
import { ExternalAuthService } from 'app/core/services/external-auth.service';
import { IntegrationService } from 'app/core/services/integration.service';
import { LoadPageComponent } from 'app/shared/pages/load-page.component';
import { WithLoadingPipe } from 'app/shared/utils/with-loading.pipe';

import { getOAuthConfig, OAuthCallbacks } from '../../config/oauth-callbacks';

@Component({
  selector: 'fs-external-auth-redirect',
  templateUrl: './external-auth-redirect.component.html',
  standalone: true,
  imports: [WithLoadingPipe, AsyncPipe, LoadPageComponent],
})
export class ExternalAuthRedirectComponent implements OnInit, ErrorHandler {
  private readonly connectionService = inject(ConnectionService);
  private readonly externalAuthService = inject(ExternalAuthService);
  private readonly integrationService = inject(IntegrationService);
  private readonly toastService = inject(ToastService);

  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);

  public externalSignInState$: Observable<Maybe<IConnection>> = of();

  async ngOnInit() {
    try {
      const integration = await this.resolveIntegration();

      const authConfig = getOAuthConfig(integration.type);

      if (!authConfig) {
        void this.handleError(`No auth config ID found for integration type ${integration.type}`);
        return;
      }

      const state = this.externalAuthService.getState();

      if (!state) {
        void this.handleError('No state found');
        return;
      }

      // Subscription occurs in the template to handle observable state and unsubscribe on destroy
      this.externalSignInState$ = this.processSignIn(authConfig, integration.type).pipe(
        map((authResult) => this.mapAuthResultToCredential(authResult)),
        switchMap((credential) =>
          this.connectionService.createConnection(state.workspaceId, {
            integrationId: integration._id,
            type: IConnectionType.OAuth,
            credential,
            metadata: this.buildCreateConnectionMetadata(integration.type),
          }),
        ),
        tap({
          error: (error) => {
            throw error;
          },
          next: () => this.clearStateAndRedirect(`/workspaces/${state.workspaceId}/connections`),
        }),
      );
    } catch (error) {
      this.handleError(error);
    }
  }

  processSignIn(authConfig: OpenIdConfiguration, integrationType: IntegrationType): Observable<AuthResult> {
    if (IntegrationUtil.isPkceSupported(integrationType)) {
      return this.externalAuthService
        .checkAuth(window.location.href, authConfig.configId!)
        .pipe(switchMap(() => this.externalAuthService.getAuthenticationResult(authConfig.configId!)));
    }

    const code = this.route.snapshot.queryParamMap.get('code');

    if (!code) {
      throw new Error('Missing query param code');
    }

    return this.externalAuthService
      .exchangeToken({
        integrationType,
        code,
        redirect_uri: authConfig.redirectUrl!,
      })
      .pipe(
        map((tokenSet) => ({
          access_token: tokenSet.access_token,
          refresh_token: tokenSet.refresh_token,
          scope: authConfig.scope,
          expires_in: tokenSet.expires_at,
        })),
      );
  }

  buildCreateConnectionMetadata(integrationType: IntegrationType): Maybe<CreateConnectionMetadata> {
    if (integrationType === IntegrationType.QUICKBOOKS) {
      const urlParams = new URLSearchParams(window.location.search);

      return {
        realmId: urlParams.get('realmId') ?? '',
      };
    }
    return undefined;
  }

  public handleError(error: unknown): void {
    let reason = 'Unknown error';

    if (typeof error === 'string') {
      reason = error;
    } else if (error instanceof Error) {
      reason = error.message;
    }

    this.toastService.error(reason);
    void this.clearStateAndRedirect('/');
  }

  private mapAuthResultToCredential(authResult: AuthResult): IOAuthCredential {
    if (!authResult.access_token) {
      throw new Error('Missing access token');
    }

    if (!authResult.refresh_token) {
      throw new Error('Missing refresh token');
    }

    if (!authResult.scope) {
      throw new Error('Missing scope');
    }

    if (authResult.error) {
      throw new Error(authResult.error);
    }

    return {
      accessToken: authResult.access_token,
      refreshToken: authResult.refresh_token,
      scope: authResult.scope,
      expiresAt: authResult.expires_in,
    };
  }

  private async resolveIntegration() {
    const integrationTypeParam = this.route.snapshot.paramMap.get('integration');
    const integrationType = Array.from(OAuthCallbacks.keys()).find(
      (integrationKey) => integrationTypeParam === integrationKey,
    );

    if (!integrationType) {
      throw new Error(`Invalid integration type ${integrationTypeParam}`);
    }

    return lastValueFrom(this.integrationService.getIntegration(integrationType));
  }

  private clearStateAndRedirect(url: string) {
    this.externalAuthService.clearState();
    return this.router.navigateByUrl(url, { replaceUrl: true });
  }
}
